<template>
    <div class="mx-auto w-full max-w-sm">
        <div>
            <h2 class="mt-6 text-3xl leading-9 font-extrabold text-gray-900">
                {{ $translate('auth.reset.title') }}
            </h2>
            <p class="mt-2 text-sm leading-5 text-gray-600 max-w">
                {{ $translate('auth.reset.or') }}
                <router-link :to="{name: 'login'}" tag="a"
                             class="font-medium text-custom-600 hover:text-custom-500 focus:outline-none focus:underline transition ease-in-out duration-150">
                    {{ $translate('auth.reset.signin') }}
                </router-link>
            </p>
        </div>
        <div class="mt-8">
            <div class="mt-6 space-y-6">
                <DataForm :form="form" v-model="values" @enter="reset"/>
                <span class="block w-full rounded-md shadow-sm">
                    <button type="submit"
                            class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-custom-600 hover:bg-custom-500 focus:outline-none focus:border-custom-700 focus:shadow-outline-custom active:bg-custom-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-custom-500"
                            @click="reset">
                            <span class="pr-2" v-if="values.loading"><i class="fal fa-spinner-third fa-spin"></i></span>
                        {{ $translate('auth.reset.reset') }}
                    </button>
                </span>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent, reactive, watch} from 'vue';
    import {BaseForm, FormGrid} from "@/types/form";
    import {PasswordField, TextField} from "@/types/field";
    import {
        EmailValidationRule, FormValidation, MinLengthValidationRule,
        OperatorValidationRule,
        RequiredValidationRule,
        ValidationOperator
    } from "@/types/validation";
    import router from "@/router";
    import {userStore} from "@/store/user";
    import {useNotification} from "@/plugins/notification";
    import {useTranslation} from "@/plugins/i18n";

    export default defineComponent({
        name: "ResetPassword",
        setup() {

            //  Notification plugin
            const notification = useNotification();

            //  Translation plugin
            const i18n = useTranslation();

            //  Values
            const values = reactive({
                loading: false,
                email: '',
                password: '',
                repeatPassword: ''
            });

            //  Form definition
            const form = reactive(new BaseForm(FormValidation.AfterSubmit, FormGrid.None));

            const emailField = new TextField('email', 'auth.reset.email')
                .setAutocomplete('username')
                .addRule(new RequiredValidationRule())
                .addRule(new EmailValidationRule())
            form.addField(emailField);

            const passwordField = new PasswordField('password', 'auth.reset.password')
                .addRule(new RequiredValidationRule())
                .addRule(new MinLengthValidationRule(8))
                .setAutocomplete('current-password');
            form.addField(passwordField);

            const repeatPasswordField =new PasswordField('repeatPassword', 'auth.reset.repeat')
                .addRule(new RequiredValidationRule())
                .addRule(new OperatorValidationRule(ValidationOperator.Equal, values.password).setMessage('auth.reset.error.not-equal', {}))
                .setAutocomplete('current-password');
            form.addField(repeatPasswordField);

            //  Check if e mail is provided as query parameter
            if (Object.keys(router.currentRoute.value.query).indexOf('email') > -1) {
                values.email = (router.currentRoute.value.query.email as string);
            }

            //  Retrieve token from URL
            let token: string = '';
            if (Object.keys(router.currentRoute.value.query).indexOf('token') > -1) {
                token = (router.currentRoute.value.query.token as string);
            }

            //  Update repeat password field, check if both passwords match
            watch(() => values, (values) => {
                repeatPasswordField
                    .removeRules()
                    .addRule(new RequiredValidationRule())
                    .addRule(new OperatorValidationRule(ValidationOperator.Equal, values.password).setMessage('auth.reset.error.not-equal', {}))
            }, {deep: true});

            //  Execute reset password
            const reset = () => {
                form.setError('').submit().then(() => {
                    values.loading = true;
                    userStore.resetPassword(token, values.email, values.password, values.repeatPassword).then(() => {
                        values.loading = false;
                        notification.success(i18n.translate('auth.reset.success.title'), i18n.translate('auth.reset.success.message'))
                        router.push({name: 'login'})
                    }).catch(error => {
                        form.setError(error);
                        values.loading = false;
                    })
                }).catch(() => {

                });
            }

            return {
                form,
                values,
                reset
            }

        }
    })
</script>