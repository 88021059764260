
    import {defineComponent, reactive, watch} from 'vue';
    import {BaseForm, FormGrid} from "@/types/form";
    import {PasswordField, TextField} from "@/types/field";
    import {
        EmailValidationRule, FormValidation, MinLengthValidationRule,
        OperatorValidationRule,
        RequiredValidationRule,
        ValidationOperator
    } from "@/types/validation";
    import router from "@/router";
    import {userStore} from "@/store/user";
    import {useNotification} from "@/plugins/notification";
    import {useTranslation} from "@/plugins/i18n";

    export default defineComponent({
        name: "ResetPassword",
        setup() {

            //  Notification plugin
            const notification = useNotification();

            //  Translation plugin
            const i18n = useTranslation();

            //  Values
            const values = reactive({
                loading: false,
                email: '',
                password: '',
                repeatPassword: ''
            });

            //  Form definition
            const form = reactive(new BaseForm(FormValidation.AfterSubmit, FormGrid.None));

            const emailField = new TextField('email', 'auth.reset.email')
                .setAutocomplete('username')
                .addRule(new RequiredValidationRule())
                .addRule(new EmailValidationRule())
            form.addField(emailField);

            const passwordField = new PasswordField('password', 'auth.reset.password')
                .addRule(new RequiredValidationRule())
                .addRule(new MinLengthValidationRule(8))
                .setAutocomplete('current-password');
            form.addField(passwordField);

            const repeatPasswordField =new PasswordField('repeatPassword', 'auth.reset.repeat')
                .addRule(new RequiredValidationRule())
                .addRule(new OperatorValidationRule(ValidationOperator.Equal, values.password).setMessage('auth.reset.error.not-equal', {}))
                .setAutocomplete('current-password');
            form.addField(repeatPasswordField);

            //  Check if e mail is provided as query parameter
            if (Object.keys(router.currentRoute.value.query).indexOf('email') > -1) {
                values.email = (router.currentRoute.value.query.email as string);
            }

            //  Retrieve token from URL
            let token: string = '';
            if (Object.keys(router.currentRoute.value.query).indexOf('token') > -1) {
                token = (router.currentRoute.value.query.token as string);
            }

            //  Update repeat password field, check if both passwords match
            watch(() => values, (values) => {
                repeatPasswordField
                    .removeRules()
                    .addRule(new RequiredValidationRule())
                    .addRule(new OperatorValidationRule(ValidationOperator.Equal, values.password).setMessage('auth.reset.error.not-equal', {}))
            }, {deep: true});

            //  Execute reset password
            const reset = () => {
                form.setError('').submit().then(() => {
                    values.loading = true;
                    userStore.resetPassword(token, values.email, values.password, values.repeatPassword).then(() => {
                        values.loading = false;
                        notification.success(i18n.translate('auth.reset.success.title'), i18n.translate('auth.reset.success.message'))
                        router.push({name: 'login'})
                    }).catch(error => {
                        form.setError(error);
                        values.loading = false;
                    })
                }).catch(() => {

                });
            }

            return {
                form,
                values,
                reset
            }

        }
    })
